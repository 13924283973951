import cn from "clsx";
import React from "react";
import { Props } from "../index";
import { useState, useEffect } from "react";
import { useTranslation } from "@/i18n/client";
import Message from "@/common-components-src/js/message";
import { sendClickTrack, EXPOSURE_RECORD } from "@/tracks/25630";
import { EXPCONFIG_FAST } from "@/common-components-src/const/point";
import JDILogin from "@/common-components-src/js/newlogin/index.newlogin";
import callAfterLogin from "@/common-components-src/js/utils/callAfterLogin";
import MessageIcon from "@/common-components-src/assets/icons/message-icon.svg";
import { fetchGetMsgEntrance } from "@/common-components-src/api/message/message.api";
import floatingToolBtnStyle from "@/common-components-src/js/floatingToolbar/components/FloatingToolBarBtn.module.scss";

const JDIBackTopBtn = ({ open, onOpen }: Props) => {
  const maxMessageCount = 100;
  const { t } = useTranslation();
  const [totalNum, setTotalNum] = useState<number>(0);
  const [showPoint, setShowPoint] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setIsOpen(open as boolean);
  }, [open]);

  const init = async () => {
    const { isLoggedIn } = await JDILogin.getInstance().getUserInfo();
    if (isLoggedIn) {
      let serviceNum = 0;
      let messageNum = 0;
      let initService = false;
      let initMessage = false;
      let isRedPoint = false;
      if (window && (window as any).zE) {
        (window as any).zE("messenger:on", "unreadMessages", function (count: number) {
          serviceNum = count;
          initService = true;
          if (initMessage) {
            setShowPoint(!serviceNum && isRedPoint);
            setTotalNum(serviceNum + messageNum);
          }
        });
      }
      const res = await fetchGetMsgEntrance();
      initMessage = true;
      if (res && res.data && res.code === "200") {
        isRedPoint = res.data.isRedPoint;
        messageNum = res.data.unReadedCount;
      }
      if (initService) {
        setShowPoint(!serviceNum && isRedPoint);
        setTotalNum(serviceNum + messageNum);
      }
    }
  };

  const handleMessageBtn = () => {
    console.log("zsf handleMessageBtn");
    callAfterLogin(() => {
      onOpen && onOpen();
      setIsOpen(true);
    });
    sendClickTrack.Sidebar_Message_Click();
  };

  return (
    <div exptag={"exp|" + EXPOSURE_RECORD.Sidebar_Message_Expo} data-exptag-config={EXPCONFIG_FAST}>
      <button className={cn(floatingToolBtnStyle.floatingBtn, floatingToolBtnStyle.heightNormal)} onClick={handleMessageBtn}>
        <div className={floatingToolBtnStyle.iconTextWrap}>
          <MessageIcon className={floatingToolBtnStyle.hoverIcon} />
          {totalNum > 0 ? (
            <div className={floatingToolBtnStyle.messageIcon}>
              <span>{totalNum < maxMessageCount ? totalNum : "99+"}</span>
            </div>
          ) : (
            showPoint && <span className={floatingToolBtnStyle.point} />
          )}
        </div>
        <div>{t("floatingToolbar.message")}</div>
      </button>
      {isOpen && (
        <Message
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
          onUpdate={(num: number) => {
            setTotalNum(num);
          }}
        />
      )}
    </div>
  );
};

export default JDIBackTopBtn;
