import cn from "clsx";
import React from "react";
import { Props } from "../index";
import { useTranslation } from "@/i18n/client";
import { sendClickTrack, EXPOSURE_RECORD } from "@/tracks/25630";
import { EXPCONFIG_FAST } from "@/common-components-src/const/point";
import callAfterLogin from "@/common-components-src/js/utils/callAfterLogin";
import CustomerSupportIcon from "@/common-components-src/assets/icons/customer-support-icon.svg";
import floatingToolBtnStyle from "@/common-components-src/js/floatingToolbar/components/FloatingToolBarBtn.module.scss";

const JDIBackTopBtn = ({ onOpen }: Props) => {
  const { t } = useTranslation();
  const handleCustomerSupport = () => {
    console.log("djh open handleCustomerSupport");
    callAfterLogin(() => {
      if (window && (window as any).zE) {
        onOpen && onOpen();
        (window as any)?.zE("messenger", "open");
      }
    });
    sendClickTrack.Sidebar_Customer_Click();
  };

  return (
    <div exptag={"exp|" + EXPOSURE_RECORD.Sidebar_Customer_Expo} data-exptag-config={EXPCONFIG_FAST}>
      <button className={cn(floatingToolBtnStyle.floatingBtn, floatingToolBtnStyle.heightLarger)} onClick={handleCustomerSupport}>
        <div className={floatingToolBtnStyle.iconTextWrap}>
          <CustomerSupportIcon className={floatingToolBtnStyle.hoverIcon} />
        </div>
        <div>{t("floatingToolbar.customerService")}</div>
      </button>
    </div>
  );
};

export default JDIBackTopBtn;
