import cn from "clsx";
import React from "react";
import { Props } from "../index";
import { useTranslation } from "@/i18n/client";
import { sendClickTrack, EXPOSURE_RECORD } from "@/tracks/25630";
import { EXPCONFIG_FAST } from "@/common-components-src/const/point";
import FeedbackController from "@/common-components-src/js/feedback/index.js";
import callAfterLogin from "@/common-components-src/js/utils/callAfterLogin";
import FeedBackIcon from "@/common-components-src/assets/icons/feedback-icon.svg";
import floatingToolBtnStyle from "@/common-components-src/js/floatingToolbar/components/FloatingToolBarBtn.module.scss";

const JDIBackTopBtn = ({ onOpen }: Props) => {
  const { t } = useTranslation();
  const handleFeedbackBtn = () => {
    callAfterLogin(() => {
      onOpen && onOpen();
      FeedbackController.getInstance().openFeedbackDialog();
    });
    sendClickTrack.Sidebar_Feedback_Click();
  };

  return (
    <div exptag={"exp|" + EXPOSURE_RECORD.Sidebar_Feedback_Expo} data-exptag-config={EXPCONFIG_FAST}>
      <button className={cn(floatingToolBtnStyle.floatingBtn, floatingToolBtnStyle.heightNormal)} onClick={handleFeedbackBtn}>
        <div className={floatingToolBtnStyle.iconTextWrap}>
          <FeedBackIcon className={floatingToolBtnStyle.hoverIcon} />
        </div>
        <div>{t("floatingToolbar.feedback")}</div>
      </button>
    </div>
  );
};

export default JDIBackTopBtn;
